<template>
  <div>
    <CRow>      
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          striped
          border
          small
          fixed
          caption="Lista de Proyectos Manzana Lote"
          icon="fas fa-map-marker"
          btn_name="proyecto manzana lote"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Proyecto Manzana"
            placeholder="Seleccione Proyecto Manzana"
            v-model="project_streetblock_lot.project_streetblock_id"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Lote"
            placeholder="Seleccione Lote"
            v-model="project_streetblock_lot.lot_id"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Metraje"
            placeholder="Digite Metraje"
            v-model="project_streetblock_lot.footage"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Monto"
            placeholder="Digite Monto"
            v-model="project_streetblock_lot.amount"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Porcentaje 24 Meses"
            placeholder="Digite Porcentaje 24 Meses"
            v-model="project_streetblock_lot.percentage_24"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Porcentaje 36 Meses"
            placeholder="Digite Porcentaje 36 Meses"
            v-model="project_streetblock_lot.percentage_36"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Proyecto manzana lote"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete },
  data() {
    return {
      prefix: "projectstreetblocklot",
      fields:[
        "Id",
        "Proyecto_Manzana",
        "Lote",
        "Metraje",
        "Monto",
        "Porcentaje_24",
        "Porcentaje_36" // resource
      ],
      data:[],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      project_streetblock_lot: {
        id: "",
        project_streetblock_id: "",
        lot_id: "",
        footage: "",
        amount: "",
        percentage_24: "",
        percentage_36: ""
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    }
  },
  computed: {
    cp_button: function () {
      if (this.project_streetblock_lot.project_streetblock_id != "" && this.project_streetblock_lot.lot_id) return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get","post"]),
    mtd_getdata: function(){
      this.get({
        url: this.$store.getters.get__url + "/projectstreetblocklot",
        token: this.$store.getters.get__token
      })
        .then((response) => {       
          this.data = response.data;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Proyecto manzana lote")
        : (this.modal.title = "Editar Proyecto manzana lote");
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.project_streetblock_lot,
      })
        .then((response) => {
          if (this.modal.action == "store") {
            this.data.push(response[0]);
          } else {
            this.data.forEach((element) => {
              if (element.Id == response[0].Id) {
                element.Proyecto_Manzana = response[0].Proyecto_Manzana;
                element.Lote = response[0].Lote;
                element.Metraje = response[0].Metraje;
                element.Monto = response[0].Monto;
                element.Porcentaje_24 = response[0].Porcentaje_24;
                element.Porcentaje_36 = response[0].Porcentaje_36;
              }
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            Proyecto_Manzana: "",
            Lote: "",
            Metraje: "",
            Monto: "",
            Porcentaje_24: "",
            Porcentaje_36: ""
          };
          /** alert succes falta */
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
          token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.project_streetblock_lot = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
     mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete= {
            boo: false,
            item: [],
          };
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search{
  color: #fff;
      background-color: #2819ae;
    border-color: #2517a3;
}
</style>